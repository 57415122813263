
export default {
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  computed: {
    leftItems() {
      return this.items.filter((item, i) => i % 2 === 0)
    },
    rightItems() {
      return this.items.filter((item, i) => i % 2 !== 0)
    },
  },
}
